/* eslint-disable */

    import React from 'react'
      import { createScope, map, transformProxies } from './helpers'

      const scripts = [
        { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=670616b651e83b2ebaf982f4").then(body => body.text()), isAsync: false },
        { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
        { loading: Promise.resolve("var Webflow=Webflow||[];Webflow.push(function(){var n;function e(){n=setTimeout(function(){var n=$(\".banner-tab-menu\").children(\".w--current:first\").next();(n.length?n:$(\".banner-tab-link:first\")).click()},4e3)}clearTimeout(n),e(),$(\".banner-tab-link\").click(function(){clearTimeout(n),e()})});"), isAsync: false },
      ]

      let Controller

      class IndexView extends React.Component {
        static get Controller() {
          if (Controller) return Controller

          try {
            Controller = require('../controllers/IndexController')
            Controller = Controller.default || Controller

            return Controller
          }
          catch (e) {
            if (e.code == 'MODULE_NOT_FOUND') {
              Controller = IndexView

              return Controller
            }

            throw e
          }
        }

        componentDidMount() {
          const htmlEl = document.querySelector('html')
          htmlEl.dataset['wfPage'] = '670616b651e83b2ebaf982fa'
          htmlEl.dataset['wfSite'] = '670616b651e83b2ebaf982f4'

          scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
            const loading = active.loading.then((script) => {
              new Function(`
                with (this) {
                  eval(arguments[0])
                }
              `).call(window, script)

              return next
            })

            return active.isAsync ? next : loading
          }))
        }

        render() {
          const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {

          }

          return (
            <span>
              <style dangerouslySetInnerHTML={{ __html: `
                @import url(/css/normalize.css);
                @import url(/css/webflow.css);
                @import url(/css/foliok12.webflow.css);

                @media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1b29f58f-e6e0-f941-a35d-ccf3fa79dde1\"] {-webkit-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);}}@media (max-width:767px) and (min-width:480px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1b29f58f-e6e0-f941-a35d-ccf3fa79dde1\"] {-webkit-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);}}@media (max-width:479px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"1b29f58f-e6e0-f941-a35d-ccf3fa79dde1\"] {-webkit-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);}}
              ` }} />
              <span className="af-view">
  <div>
    <div className="af-class-banner-area">
      <div className="af-class-banner-top-image-wrap">
        <div className="af-class-banner-triangle-wrap"><img src="images/banner-polygon-element.svg" loading="lazy" alt="triangle" /></div><img src="images/top-area-bg-gradient.png" loading="lazy" sizes="100vw" srcSet="images/top-area-bg-gradient-p-500.png 500w, images/top-area-bg-gradient-p-800.png 800w, images/top-area-bg-gradient-p-1080.png 1080w, images/top-area-bg-gradient.png 1512w" alt className="af-class-banner-bg-image" /><img src="images/bottom-area-bg-gradient.png" loading="lazy" sizes="100vw" srcSet="images/bottom-area-bg-gradient-p-500.png 500w, images/bottom-area-bg-gradient-p-800.png 800w, images/bottom-area-bg-gradient-p-1080.png 1080w, images/bottom-area-bg-gradient.png 1512w" alt className="af-class-banner-bg-image" />
      </div>
      <div className="af-class-top-area">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div className="af-class-dropdown-container">
            <div data-hover="false" data-delay={0} className="af-class-dropdown w-dropdown">
              <div className="af-class-dropdown-toggle-2 w-dropdown-toggle" />
              <nav className="af-class-dropdown-list w-dropdown-list">
                <a href="https://app.foliok12.com/login" target="_blank" className="af-class-dropdown-link af-class-log-in-button w-dropdown-link">Log In</a>
                <a href="https://app.foliok12.com/create-account" className="af-class-dropdown-link af-class-sign-up-button w-dropdown-link">Sign Up</a>
              </nav>
            </div>
          </div>
          <div className="af-class-logo-area"><img src="images/folio-logo.svg" loading="lazy" alt="Folio Logo" className="af-class-image" />
            <div className="af-class-div-block">
              <a href="https://app.foliok12.com/login" target="_blank" className="af-class-log-in-button w-button">Log In</a>
              <a href="https://app.foliok12.com/create-account" target="_blank" className="af-class-sign-up-button w-button">Subscribe now<br /></a>
            </div>
          </div>
        </div>
      </div>
      <section className="af-class-banner-section">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div className="w-layout-grid af-class-banner-grid">
            <div id="w-node-_72273628-e5ce-1981-e88d-dac23c61b5fa-baf982fa" className="af-class-banner-content-wrap">
              <h1 className="af-class-banner-title">Engage students with <span className="af-class-gradient-text">visual content they’ll recall.</span></h1>
              <div className="af-class-banner-subtitle-wrap">
                <p className="af-class-banner-subtitle af-class-gradient-text">Unlimited access for $5 per month.</p>
              </div>
              <div className="af-class-banner-text-wrap">
                <p>Folio is a library of standards-aligned, visually engaging classroom content to fuel your lessons. Our collective of K-8 teachers AND graphic designers started with the questions most often missed on standardized tests. Available in English &amp; Spanish.</p>
                <div className="af-class-banner-text-arrow"><img src="images/banner-arrow.svg" loading="lazy" alt="curve arrow" /></div>
              </div>
              <div className="af-class-banner-button-wrap">
                <a href="https://app.foliok12.com/create-account" target="_blank" className="af-class-primary-button w-inline-block">
                  <div className="af-class-primary-button-wrap">
                    <div>Subscribe now</div>
                  </div>
                </a>
                <a href="https://app.foliok12.com/library" target="_blank" className="af-class-yellow-button w-button">Preview Folio</a>
              </div>
              <p className="af-class-banner-notice-text">$5 per month. Cancel Anytime.</p>
            </div>
            <div id="w-node-c83c749d-a761-c6b2-b242-4fb94a24ac8e-baf982fa">
              <div data-current="Tab 1" data-easing="linear" data-duration-in={300} data-duration-out={100} className="af-class-banner-image-tab w-tabs">
                <div className="af-class-banner-tab-menu w-tab-menu">
                  <a data-w-tab="Tab 1" id="tab1" className="af-class-banner-tab-link w-inline-block w-tab-link w--current">
                    <div className="w-embed"><svg xmlns="http://www.w3.org/2000/svg" width={22} height={15} viewBox="0 0 22 15" fill="none">
                        <path d="M13.3891 13.0342C13.3903 13.0669 13.385 13.0996 13.3736 13.1303C13.3622 13.161 13.3449 13.1892 13.3225 13.2132C13.3002 13.2372 13.2734 13.2566 13.2436 13.2702C13.2138 13.2838 13.1816 13.2914 13.1489 13.2926H9.42408C9.35778 13.2903 9.2951 13.2618 9.24979 13.2134C9.20449 13.1649 9.18025 13.1005 9.18242 13.0342V12.5396H0.873352V13.3659C0.868043 13.5405 0.932147 13.7101 1.05163 13.8376C1.17111 13.9651 1.33624 14.04 1.51086 14.046H21.3584C21.5329 14.0399 21.6977 13.9649 21.8169 13.8374C21.9361 13.7099 21.9998 13.5403 21.9941 13.3659V12.5396H13.3891V13.0342Z" fill="currentcolor" />
                        <path d="M19.9951 1.72317C19.995 1.58746 19.9682 1.45309 19.9161 1.32775C19.8641 1.20242 19.7879 1.08856 19.6918 0.992699C19.5957 0.896836 19.4817 0.820843 19.3563 0.769062C19.2308 0.717281 19.0964 0.690727 18.9607 0.690919H3.61245C3.4768 0.690775 3.34245 0.717369 3.21709 0.769181C3.09172 0.820992 2.9778 0.897004 2.88183 0.992872C2.78586 1.08874 2.70973 1.20258 2.65778 1.32789C2.60584 1.4532 2.5791 1.58752 2.5791 1.72317V11.9495H19.9951V1.72317ZM18.9242 10.7066H3.6489V2.19665H18.9242V10.7066Z" fill="currentcolor" />
                      </svg></div>
                    <div>Laptops</div>
                  </a>
                  <a data-w-tab="Tab 2" id="tab2" className="af-class-banner-tab-link af-class-blue w-inline-block w-tab-link">
                    <div className="w-embed"><svg xmlns="http://www.w3.org/2000/svg" width={13} height={16} viewBox="0 0 13 16" fill="none">
                        <path d="M11.4959 0.742079L1.39889 0.742078C1.02653 0.742538 0.669554 0.890659 0.406258 1.15396C0.142962 1.41725 -0.00516034 1.77423 -0.0056207 2.14658L-0.00562179 14.5899C-0.00516149 14.9622 0.14296 15.3192 0.406257 15.5825C0.669553 15.8458 1.02653 15.9939 1.39888 15.9944L11.4959 15.9944C11.8682 15.9939 12.2252 15.8458 12.4885 15.5825C12.7518 15.3192 12.8999 14.9622 12.9004 14.5899L12.9004 2.14658C12.8999 1.77423 12.7518 1.41725 12.4885 1.15396C12.2252 0.89066 11.8682 0.742539 11.4959 0.742079ZM8.06725 15.0978C8.06738 15.1255 8.06193 15.1529 8.05123 15.1784C8.04053 15.204 8.0248 15.2271 8.00497 15.2464C7.98557 15.2661 7.96244 15.2817 7.93694 15.2924C7.91144 15.3031 7.88407 15.3086 7.85641 15.3087L5.03836 15.3087C4.99635 15.3095 4.95508 15.2976 4.91993 15.2746C4.88478 15.2516 4.85739 15.2185 4.84134 15.1797C4.8253 15.1409 4.82134 15.0981 4.82998 15.057C4.83862 15.0159 4.85947 14.9783 4.8898 14.9493C4.89267 14.9462 4.89581 14.9434 4.89919 14.9409C4.93719 14.9062 4.98688 14.8871 5.03836 14.8873L7.85641 14.8873C7.90789 14.8871 7.95758 14.9062 7.99558 14.9409C8.01812 14.9604 8.0362 14.9845 8.04857 15.0115C8.06094 15.0386 8.06731 15.0681 8.06725 15.0978ZM11.8469 2.14658L11.8469 14.5036L1.04785 14.5036L1.04785 2.14658C1.04748 2.06155 1.07859 1.97939 1.13517 1.91592L11.7593 1.91592C11.816 1.97934 11.8472 2.06151 11.8469 2.14658Z" fill="currentcolor" />
                      </svg></div>
                    <div>Tablets</div>
                  </a>
                  <a data-w-tab="Tab 3" id="tab3" className="af-class-banner-tab-link af-class-green w-inline-block w-tab-link">
                    <div className="w-embed"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
                        <path d="M8.03098 0.869452C7.94436 0.876233 7.8636 0.915835 7.8052 0.98017C7.7468 1.04451 7.71518 1.12871 7.71679 1.21559V1.89723H8.39843V1.21559C8.39931 1.16805 8.39024 1.12086 8.3718 1.07705C8.35336 1.03323 8.32596 0.993755 8.29135 0.961157C8.25675 0.928559 8.21571 0.903561 8.17087 0.887768C8.12603 0.871975 8.07838 0.865734 8.03098 0.869452ZM0.900391 2.23805V3.26051H15.2148V2.23805H0.900391ZM1.75244 3.60133V11.9514C1.75244 12.0458 1.82844 12.1218 1.92285 12.1218H14.1924C14.2868 12.1218 14.3628 12.0458 14.3628 11.9514V3.60133H1.75244ZM7.71679 12.4626V13.0058L5.59199 15.2957C5.56157 15.3286 5.53792 15.3671 5.52239 15.4091C5.50687 15.4511 5.49976 15.4958 5.50149 15.5405C5.50323 15.5853 5.51375 15.6293 5.53248 15.67C5.5512 15.7106 5.57776 15.7472 5.61063 15.7777C5.64349 15.8081 5.68203 15.8317 5.72404 15.8472C5.76605 15.8628 5.8107 15.8699 5.85546 15.8682C5.90021 15.8664 5.94418 15.8559 5.98487 15.8372C6.02555 15.8185 6.06215 15.7919 6.09257 15.759L7.71679 14.0123V15.1892C7.71615 15.2344 7.7245 15.2792 7.74134 15.3211C7.75818 15.363 7.78318 15.4012 7.81489 15.4333C7.84661 15.4655 7.88439 15.491 7.92606 15.5085C7.96773 15.5259 8.01244 15.5349 8.05761 15.5349C8.10278 15.5349 8.1475 15.5259 8.18916 15.5085C8.23083 15.491 8.26862 15.4655 8.30033 15.4333C8.33204 15.4012 8.35704 15.363 8.37389 15.3211C8.39073 15.2792 8.39907 15.2344 8.39843 15.1892V14.0123L10.0227 15.759C10.0531 15.7919 10.0897 15.8185 10.1304 15.8372C10.171 15.8559 10.215 15.8664 10.2598 15.8682C10.3045 15.8699 10.3492 15.8628 10.3912 15.8472C10.4332 15.8317 10.4717 15.8081 10.5046 15.7777C10.5375 15.7472 10.564 15.7106 10.5827 15.67C10.6015 15.6293 10.612 15.5853 10.6137 15.5405C10.6155 15.4958 10.6084 15.4511 10.5928 15.4091C10.5773 15.3671 10.5537 15.3286 10.5232 15.2957L8.39843 13.0058V12.4626H7.71679Z" fill="currentcolor" />
                      </svg></div>
                    <div>Smart-boards</div>
                  </a>
                  <a data-w-tab="Tab 4" id="tab4" className="af-class-banner-tab-link af-class-violet w-inline-block w-tab-link">
                    <div className="w-embed"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} viewBox="0 0 20 16" fill="none">
                        <path d="M17.7763 4.45728H1.56572C0.822739 4.45728 0.214844 5.05841 0.214844 5.80815V12.2383C0.214844 12.9813 0.822739 13.5892 1.56572 13.5892H2.35092L3.07871 11.1306C2.87185 11.0149 2.72664 10.8022 2.72664 10.5489C2.72664 10.1757 3.02889 9.87346 3.40208 9.87346H15.94C16.3132 9.87346 16.6154 10.1757 16.6154 10.5489C16.6154 10.8022 16.4702 11.0149 16.2633 11.1306L16.9911 13.5892H17.7763C18.5193 13.5892 19.1272 12.9813 19.1272 12.2383V5.80815C19.1272 5.05841 18.5193 4.45728 17.7763 4.45728ZM14.4607 7.69679C14.0875 7.69679 13.7852 7.39454 13.7852 7.02135C13.7852 6.64816 14.0875 6.34591 14.4607 6.34591C14.8339 6.34591 15.1361 6.64816 15.1361 7.02135C15.1361 7.3937 14.833 7.69679 14.4607 7.69679ZM16.5604 7.69679C16.1873 7.69679 15.885 7.39454 15.885 7.02135C15.885 6.64816 16.1873 6.34591 16.5604 6.34591C16.9336 6.34591 17.2359 6.64816 17.2359 7.02135C17.2359 7.3937 16.9336 7.69679 16.5604 7.69679Z" fill="currentcolor" />
                        <path d="M15.9995 15.0012L14.8817 11.2246H4.45959L3.34173 15.0012C3.2134 15.4344 3.53845 15.8683 3.98931 15.8683H15.3518C15.8027 15.8683 16.1279 15.4344 15.9995 15.0012ZM12.1782 13.8851H7.16219C6.789 13.8851 6.48675 13.5828 6.48675 13.2096C6.48675 12.8364 6.789 12.5342 7.16219 12.5342H12.1782C12.5514 12.5342 12.8536 12.8364 12.8536 13.2096C12.8536 13.582 12.5522 13.8851 12.1782 13.8851Z" fill="currentcolor" />
                        <path d="M15.5861 3.10641V1.5436C15.5861 1.17042 15.2839 0.868164 14.9107 0.868164H4.4313C4.05811 0.868164 3.75586 1.17042 3.75586 1.5436V3.10641H15.5861Z" fill="currentcolor" />
                      </svg></div>
                    <div>Prints</div>
                  </a>
                </div>
                <div className="w-tab-content">
                  <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                    <div className="af-class-banner-image-wrap"><img width={664} sizes="(max-width: 479px) 92vw, (max-width: 767px) 93vw, 664px" alt="Folio Banner Image" src="images/banner-image-1.png" loading="eager" srcSet="images/banner-image-1-p-500.png 500w, images/banner-image-1-p-800.png 800w, images/banner-image-1.png 1328w" />
                      <div className="af-class-banner-image-text-area">
                        <p className="af-class-banner-image-text">Use Folio on student laptops, SMART boards, &nbsp;teaching apps, or print.</p>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Tab 2" className="w-tab-pane">
                    <div className="af-class-banner-image-wrap"><img width={664} sizes="(max-width: 479px) 92vw, (max-width: 767px) 93vw, 664px" alt="Folio Banner Image" src="images/banner-image-2.png" loading="eager" srcSet="images/banner-image-2-p-500.png 500w, images/banner-image-2-p-800.png 800w, images/banner-image-2.png 1328w" />
                      <div className="af-class-banner-image-text-area">
                        <p className="af-class-banner-image-text">Use Folio on student laptops, SMART boards, &nbsp;teaching apps, or print.</p>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Tab 3" className="w-tab-pane">
                    <div className="af-class-banner-image-wrap"><img width={664} sizes="(max-width: 479px) 92vw, (max-width: 767px) 93vw, 664px" alt="Folio Banner Image" src="images/banner-image-3.png" loading="eager" srcSet="images/banner-image-3-p-500.png 500w, images/banner-image-3-p-800.png 800w, images/banner-image-3.png 1367w" />
                      <div className="af-class-banner-image-text-area">
                        <p className="af-class-banner-image-text">Use Folio on student laptops, SMART boards, &nbsp;teaching apps, or print.</p>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Tab 4" className="w-tab-pane">
                    <div className="af-class-banner-image-wrap"><img width={664} sizes="(max-width: 479px) 92vw, (max-width: 767px) 93vw, 664px" alt="Folio Banner Image" src="images/banner-image-4.png" loading="eager" srcSet="images/banner-image-4-p-500.png 500w, images/banner-image-4-p-800.png 800w, images/banner-image-4.png 1329w" />
                      <div className="af-class-banner-image-text-area">
                        <p className="af-class-banner-image-text">Use Folio on student laptops, SMART boards, &nbsp;teaching apps, or print.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="af-class-banner-logo-text">Compatible with:</h2>
                <div className="af-class-compatiable-image-wrap">
                  <div className="af-class-compatiable-image-holder"><img src="images/smart-technologies__40329.original.png" loading="lazy" width={640} sizes="(max-width: 479px) 100vw, (max-width: 991px) 22vw, 23vw" alt="smart logo" srcSet="images/smart-technologies__40329.original-p-500.png 500w, images/smart-technologies__40329.original-p-800.png 800w, images/smart-technologies__40329.original-p-1080.png 1080w, images/smart-technologies__40329.original.png 1280w" /></div>
                  <div className="af-class-compatiable-image-holder"><img src="images/Seesaw-Logotype-PMS2090-1024x257.png" loading="lazy" width={640} sizes="(max-width: 479px) 100vw, (max-width: 991px) 22vw, 23vw" alt="seesaw logo" srcSet="images/Seesaw-Logotype-PMS2090-1024x257-p-500.png 500w, images/Seesaw-Logotype-PMS2090-1024x257-p-800.png 800w, images/Seesaw-Logotype-PMS2090-1024x257.png 1024w" /></div>
                  <div className="af-class-compatiable-image-holder"><img src="images/Google-Classroom-Logo.png" loading="lazy" width={640} sizes="(max-width: 479px) 100vw, (max-width: 991px) 22vw, 23vw" alt="google class room" srcSet="images/Google-Classroom-Logo-p-500.png 500w, images/Google-Classroom-Logo-p-800.png 800w, images/Google-Classroom-Logo-p-1080.png 1080w, images/Google-Classroom-Logo-p-1600.png 1600w, images/Google-Classroom-Logo.png 3840w" /></div>
                  <div className="af-class-compatiable-image-holder"><img src="images/Nearpod-logo.png" loading="lazy" width={640} sizes="(max-width: 479px) 100vw, (max-width: 991px) 22vw, 23vw" alt="nearpod logo" srcSet="images/Nearpod-logo-p-500.png 500w, images/Nearpod-logo-p-800.png 800w, images/Nearpod-logo-p-1080.png 1080w, images/Nearpod-logo.png 1100w" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="af-class-partner-section">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div>
            <h2 className="af-class-partner-section-title">Our products are trusted by more than 2,500 schools</h2>
          </div>
          <div data-w-id="55cd9d31-909b-9fb0-5de2-b58826a8fb0a" className="af-class-partner-logo-wrap">
            <div className="af-class-partner-logo-holder">
              <div style={{WebkitTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}} className="af-class-partner-logo-area">
                <div className="af-class-partner-logo"><img src="images/partner-logo-1.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-2.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-3.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-4.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-5.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-6.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-7.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-8.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-9.png" loading="lazy" alt="Folio Partner Logo" /></div>
              </div>
              <div style={{WebkitTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}} className="af-class-partner-logo-area">
                <div className="af-class-partner-logo"><img src="images/partner-logo-1.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-2.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-3.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-4.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-5.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-6.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-7.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-8.png" loading="lazy" alt="Folio Partner Logo" /></div>
                <div className="af-class-partner-logo"><img src="images/partner-logo-9.png" loading="lazy" alt="Folio Partner Logo" /></div>
              </div>
            </div>
            <div className="af-class-partner-logo-overlay" />
            <div className="af-class-partner-logo-overlay af-class-left" />
          </div>
          <a href="#" className="af-class-video-area w-inline-block w-lightbox"><img src="images/video-thumbnail.jpg" loading="lazy" sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 97vw" srcSet="images/video-thumbnail-p-500.jpg 500w, images/video-thumbnail-p-800.jpg 800w, images/video-thumbnail-p-1080.jpg 1080w, images/video-thumbnail.jpg 1285w" alt="Video Thumbnail Image" className="af-class-video-thumbnail-image" />
            <div className="af-class-video-play-icon"><img src="images/play-icon.png" loading="lazy" alt="Play Icon" /></div>
          </a>
        </div>
      </section>
      <section className="af-class-transform-section">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div className="af-class-transform-section-title-wrap">
            <h2 className="af-class-section-title">Discover how Folio transforms these <span className="af-class-gradient-text">challenges into opportunities</span> for better teaching and learning experiences.</h2>
          </div>
          <div className="af-class-transform-area">
            <div className="af-class-transform-card">
              <h3 className="af-class-transform-card-title">Before</h3>
              <div className="af-class-transform-list-area">
                <div className="af-class-transform-list-item">
                  <div className="af-class-transform-list-icon"><img src="images/cross-icon.svg" loading="lazy" alt="cross Icon" /></div>
                  <div>Teachers spend 3+ hours a night lesson planning and $100s of their own dollars buying individual resources.</div>
                </div>
                <div className="af-class-transform-list-item">
                  <div className="af-class-transform-list-icon"><img src="images/cross-icon.svg" loading="lazy" alt="cross Icon" /></div>
                  <div>New teachers feel overwhelmed, unsure of how to structure effective lessons.</div>
                </div>
                <div className="af-class-transform-list-item">
                  <div className="af-class-transform-list-icon"><img src="images/cross-icon.svg" loading="lazy" alt="cross Icon" /></div>
                  <div>Teachers face challenges engaging English Language Learners (ELL).</div>
                </div>
              </div>
            </div>
            <div className="af-class-transform-arrow-wrap"><img src="images/transform-arrow.svg" loading="lazy" alt="arrow" /></div>
            <div className="af-class-transform-card af-class-after">
              <h3 className="af-class-transform-card-title af-class-white">After using Folio</h3>
              <div className="af-class-transform-list-area">
                <div className="af-class-transform-list-item af-class-white">
                  <div className="af-class-transform-list-icon"><img src="images/tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                  <div>Lesson planning is now streamlined, allowing teachers to quickly create plans, freeing up valuable time for other activities.</div>
                </div>
                <div className="af-class-transform-list-item af-class-white">
                  <div className="af-class-transform-list-icon"><img src="images/tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                  <div>Guided templates and resources help new teachers build effective lessons confidently, feeling more organized and empowered.</div>
                </div>
                <div className="af-class-transform-list-item af-class-white">
                  <div className="af-class-transform-list-icon"><img src="images/tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                  <div>Multilingual support and resources ensure all students are supported, enabling teachers to effectively communicate and teach in multiple languages.</div>
                </div>
              </div>
              <div className="af-class-transform-vector-wrap"><img src="images/transform-card-hex-vector.svg" loading="lazy" alt /></div>
            </div>
          </div>
          <div data-current="Tab 1" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-transform-tab w-tabs">
            <div className="af-class-transform-tab-menu w-tab-menu">
              <a data-w-tab="Tab 1" className="af-class-teansform-tab-link w-inline-block w-tab-link w--current">
                <div>Before</div>
              </a>
              <a data-w-tab="Tab 2" className="af-class-teansform-tab-link w-inline-block w-tab-link">
                <div>After</div>
              </a>
            </div>
            <div className="w-tab-content">
              <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                <div className="af-class-transform-card">
                  <h3 className="af-class-transform-card-title">Before</h3>
                  <div className="af-class-transform-list-area">
                    <div className="af-class-transform-list-item">
                      <div className="af-class-transform-list-icon"><img src="images/cross-icon.svg" loading="lazy" alt="cross Icon" /></div>
                      <div>Teachers spend 3+ hours a night lesson planning and $100s of their own dollars buying individual resources.</div>
                    </div>
                    <div className="af-class-transform-list-item">
                      <div className="af-class-transform-list-icon"><img src="images/cross-icon.svg" loading="lazy" alt="cross Icon" /></div>
                      <div>New teachers feel overwhelmed, unsure of how to structure effective lessons.</div>
                    </div>
                    <div className="af-class-transform-list-item">
                      <div className="af-class-transform-list-icon"><img src="images/cross-icon.svg" loading="lazy" alt="cross Icon" /></div>
                      <div>Teachers face challenges engaging English Language Learners (ELL).</div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-w-tab="Tab 2" className="w-tab-pane">
                <div className="af-class-transform-card af-class-after">
                  <h3 className="af-class-transform-card-title af-class-white">After using Folio</h3>
                  <div className="af-class-transform-list-area">
                    <div className="af-class-transform-list-item af-class-white">
                      <div className="af-class-transform-list-icon"><img src="images/tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                      <div>Lesson planning is now streamlined, allowing teachers to quickly create plans, freeing up valuable time for other activities.</div>
                    </div>
                    <div className="af-class-transform-list-item af-class-white">
                      <div className="af-class-transform-list-icon"><img src="images/tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                      <div>Guided templates and resources help new teachers build effective lessons confidently, feeling more organized and empowered.</div>
                    </div>
                    <div className="af-class-transform-list-item af-class-white">
                      <div className="af-class-transform-list-icon"><img src="images/tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                      <div>Multilingual support and resources ensure all students are supported, enabling teachers to effectively communicate and teach in multiple languages.</div>
                    </div>
                  </div>
                  <div className="af-class-transform-vector-wrap"><img loading="lazy" src="images/transform-card-hex-vector.svg" alt /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="af-class-engaging-section">
      <div className="w-layout-blockcontainer af-class-container w-container">
        <div className="af-class-align-center">
          <div className="af-class-engaging-section-title-wrap">
            <h2 className="af-class-section-title">Engaging and Effective Content</h2>
            <p>Discover how Folio's innovative resources combine high-quality visuals, collaborative design, and bilingual support to enhance learning and save preparation time for educators.</p>
          </div>
        </div>
        <div data-current="Tab 1" data-easing="ease" data-duration-in={300} data-duration-out={100} className="af-class-engaging-tab w-tabs">
          <div className="af-class-engaging-tab-menu w-tab-menu">
            <a data-w-tab="Tab 1" className="af-class-engaging-tab-link w-inline-block w-tab-link w--current">
              <div className="af-class-engaging-tab-icon-wrap">
                <div className="af-class-engaging-tab-icon w-embed"><svg xmlns="http://www.w3.org/2000/svg" width={22} height={25} viewBox="0 0 22 25" fill="none">
                    <path d="M21.7478 6.87214L11.2478 0.872141C11.0938 0.784156 10.9058 0.784156 10.7518 0.872141L0.251817 6.87214C0.0958169 6.96116 -0.000183105 7.12714 -0.000183105 7.30616V18.3062C-0.000183105 18.4852 0.0958169 18.6512 0.251817 18.7402L10.7518 24.7402C10.8288 24.7842 10.9138 24.8062 10.9998 24.8062C11.0858 24.8062 11.1708 24.7842 11.2478 24.7402L21.7478 18.7402C21.9038 18.6512 21.9998 18.4852 21.9998 18.3062V7.30616C21.9998 7.12714 21.9038 6.96116 21.7478 6.87214ZM20.9998 18.0162L10.9998 23.7301L0.999801 18.0162V7.59612L10.9998 1.88216L20.9998 7.59617V18.0162Z" fill="currentcolor" />
                  </svg></div>
                <div className="af-class-engaging-tab-icon-wrap-bg" />
              </div>
              <div>
                <div>Folio content is aligned to state standards to ensure student success on evaluations</div>
                <div className="af-class-engaging-tab-text-wrap">
                  <p className="af-class-engaging-tab-text">Folio engages students with memorable visuals, disciplined design, and saves prep time.</p>
                </div>
              </div>
            </a>
            <a data-w-tab="Tab 2" className="af-class-engaging-tab-link w-inline-block w-tab-link">
              <div className="af-class-engaging-tab-icon-wrap">
                <div className="af-class-engaging-tab-icon w-embed"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} viewBox="0 0 24 25" fill="none">
                    <g clipPath="url(#clip0_461_228)">
                      <path d="M5 6.80615C3.346 6.80615 2 5.46015 2 3.80615C2 2.15215 3.346 0.806152 5 0.806152C6.654 0.806152 8 2.15215 8 3.80615C8 5.46015 6.654 6.80615 5 6.80615ZM5 1.80615C3.897 1.80615 3 2.70315 3 3.80615C3 4.90915 3.897 5.80615 5 5.80615C6.103 5.80615 7 4.90915 7 3.80615C7 2.70315 6.103 1.80615 5 1.80615Z" fill="currentcolor" />
                      <path d="M6.5 24.8062H3.5C2.673 24.8062 2 24.1332 2 23.3062V17.9242C2 17.7332 1.894 17.5622 1.724 17.4772L0.829 17.0302C0.318 16.7742 0 16.2602 0 15.6882V11.3062C0 9.37615 1.57 7.80615 3.5 7.80615H6.5C8.43 7.80615 10 9.37615 10 11.3062V15.6882C10 16.2602 9.682 16.7742 9.171 17.0302L8.276 17.4772C8.106 17.5622 8 17.7332 8 17.9242V23.3062C8 24.1332 7.327 24.8062 6.5 24.8062ZM3.5 8.80615C2.122 8.80615 1 9.92815 1 11.3062V15.6882C1 15.8792 1.106 16.0502 1.276 16.1352L2.171 16.5822C2.682 16.8382 3 17.3522 3 17.9242V23.3062C3 23.5822 3.224 23.8062 3.5 23.8062H6.5C6.776 23.8062 7 23.5822 7 23.3062V17.9242C7 17.3522 7.318 16.8382 7.829 16.5822L8.724 16.1352C8.894 16.0502 9 15.8792 9 15.6882V11.3062C9 9.92815 7.878 8.80615 6.5 8.80615H3.5Z" fill="currentcolor" />
                      <path d="M21.5 16.8062H12.5C12.224 16.8062 12 16.5822 12 16.3062C12 16.0302 12.224 15.8062 12.5 15.8062H21.5C22.327 15.8062 23 15.1332 23 14.3062V5.30615C23 4.47915 22.327 3.80615 21.5 3.80615H10.5C10.224 3.80615 10 3.58215 10 3.30615C10 3.03015 10.224 2.80615 10.5 2.80615H21.5C22.878 2.80615 24 3.92815 24 5.30615V14.3062C24 15.6842 22.878 16.8062 21.5 16.8062Z" fill="currentcolor" />
                      <path d="M19.5 13.8062H12.5C12.224 13.8062 12 13.5822 12 13.3062V6.30615C12 6.03015 12.224 5.80615 12.5 5.80615C12.776 5.80615 13 6.03015 13 6.30615V12.8062H19.5C19.776 12.8062 20 13.0302 20 13.3062C20 13.5822 19.776 13.8062 19.5 13.8062Z" fill="currentcolor" />
                      <path d="M15.229 13.8062C15.143 13.8062 15.056 13.7842 14.977 13.7382C14.739 13.5982 14.659 13.2922 14.798 13.0532C14.928 12.8312 15 12.5662 15 12.3062C15 11.4792 14.327 10.8062 13.5 10.8062C13.24 10.8062 12.975 10.8782 12.753 11.0082C12.514 11.1472 12.208 11.0682 12.068 10.8292C11.929 10.5912 12.008 10.2842 12.247 10.1442C12.625 9.92315 13.059 9.80615 13.5 9.80615C14.878 9.80615 16 10.9282 16 12.3062C16 12.7472 15.883 13.1812 15.662 13.5592C15.568 13.7182 15.401 13.8062 15.229 13.8062Z" fill="currentcolor" />
                    </g>
                    <defs>
                      <clipPath id="clip0_461_228">
                        <rect width={24} height={24} fill="white" transform="translate(0 0.806152)" />
                      </clipPath>
                    </defs>
                  </svg></div>
                <div className="af-class-engaging-tab-icon-wrap-bg" />
              </div>
              <div>
                <div>Teachers and Graphic Designers collaborate on each resource</div>
                <div className="af-class-engaging-tab-text-wrap">
                  <p className="af-class-engaging-tab-text">Folio blends teaching principles with design to create engaging resources.</p>
                </div>
              </div>
            </a>
            <a data-w-tab="Tab 3" className="af-class-engaging-tab-link w-inline-block w-tab-link">
              <div className="af-class-engaging-tab-icon-wrap">
                <div className="af-class-engaging-tab-icon w-embed"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                    <path d="M21.5 14C21.224 14 21 13.776 21 13.5V3.5C21 2.673 20.327 2 19.5 2H4.5C3.673 2 3 2.673 3 3.5V13.5C3 13.776 2.776 14 2.5 14C2.224 14 2 13.776 2 13.5V3.5C2 2.121 3.122 1 4.5 1H19.5C20.878 1 22 2.121 22 3.5V13.5C22 13.776 21.776 14 21.5 14Z" fill="currentcolor" />
                    <path d="M6.5 23C6.434 23 6.367 22.987 6.303 22.96C6.049 22.851 5.931 22.557 6.04 22.303L9.04 15.303C9.149 15.049 9.443 14.933 9.696 15.04C9.95 15.149 10.068 15.443 9.959 15.697L6.959 22.697C6.878 22.887 6.694 23 6.5 23Z" fill="currentcolor" />
                    <path d="M17.5 22.9999C17.306 22.9999 17.122 22.8869 17.041 22.6969L14.041 15.6969C13.932 15.4429 14.05 15.1489 14.304 15.0399C14.554 14.9319 14.851 15.0489 14.96 15.3029L17.96 22.3029C18.069 22.5569 17.951 22.8509 17.697 22.9599C17.633 22.9869 17.566 22.9999 17.5 22.9999Z" fill="currentcolor" />
                    <path d="M12 21C11.724 21 11.5 20.776 11.5 20.5V15.5C11.5 15.224 11.724 15 12 15C12.276 15 12.5 15.224 12.5 15.5V20.5C12.5 20.776 12.276 21 12 21Z" fill="currentcolor" />
                    <path d="M23.5 16H0.5C0.224 16 0 15.776 0 15.5V13.5C0 13.224 0.224 13 0.5 13H23.5C23.776 13 24 13.224 24 13.5V15.5C24 15.776 23.776 16 23.5 16ZM1 15H23V14H1V15Z" fill="currentcolor" />
                  </svg></div>
                <div className="af-class-engaging-tab-icon-wrap-bg" />
              </div>
              <div>
                <div>Hundreds of English + Spanish Language Resources</div>
                <div className="af-class-engaging-tab-text-wrap">
                  <p className="af-class-engaging-tab-text">Over 5 million English learners lack high-quality resources. Folio helps teachers reach all.</p>
                </div>
              </div>
            </a>
          </div>
          <div className="af-class-engaging-tab-content w-tab-content">
            <div data-w-tab="Tab 1" className="af-class-engaging-tab-pane w-tab-pane w--tab-active">
              <div className="af-class-engaging-image-wrap"><img width={419} sizes="(max-width: 479px) 87vw, (max-width: 767px) 419px, (max-width: 991px) 48vw, 419px" alt="engage Image" src="images/engaging-image-1.png" loading="lazy" srcSet="images/engaging-image-1-p-500.png 500w, images/engaging-image-1.png 838w" /></div>
            </div>
            <div data-w-tab="Tab 2" className="af-class-engaging-tab-pane w-tab-pane">
              <div className="af-class-engaging-image-wrap"><img width="518.5" sizes="(max-width: 479px) 87vw, (max-width: 767px) 89vw, (max-width: 1279px) 50vw, 518.5px" alt="engage Image" src="images/engaging-image-2.png" loading="lazy" srcSet="images/engaging-image-2-p-500.png 500w, images/engaging-image-2-p-800.png 800w, images/engaging-image-2.png 1037w" /></div>
            </div>
            <div data-w-tab="Tab 3" className="af-class-engaging-tab-pane w-tab-pane">
              <div className="af-class-engaging-image-wrap"><img width={469} sizes="(max-width: 479px) 87vw, (max-width: 767px) 88vw, (max-width: 991px) 49vw, 469px" alt="engage Image" src="images/engaging-image-3.png" loading="lazy" srcSet="images/engaging-image-3-p-500.png 500w, images/engaging-image-3.png 938w" /></div>
            </div>
          </div>
        </div>
        <div className="af-class-engaging-tab-button-wrap">
          <a href="https://app.foliok12.com/create-account" target="_blank" className="af-class-primary-button w-inline-block">
            <div className="af-class-blue-button-wrap">
              <div>Subscribe now</div>
            </div>
          </a>
        </div>
      </div>
    </section>
    <div className="af-class-category-area">
      <section className="af-class-download-section">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div className="af-class-align-center">
            <div className="af-class-download-section-title-wrap">
              <div className="af-class-download-section-title-area">
                <h2 className="af-class-section-title">Unlimited downloads for only <span className="af-class-gradient-text">$5/month</span></h2>
                <div className="af-class-download-section-title-vector"><img src="images/download-title-vector.svg" loading="lazy" alt /></div>
              </div>
              <p>Access a vast library of resources designed for teachers and homeschooling parents, available for purchase and printing at a low monthly cost to enhance lessons and education.</p>
            </div>
          </div>
          <div className="af-class-download-slider-wrap">
            <div className="af-class-download-slider-title-wrap">
              <h3 className="af-class-partner-section-title">We started with the most challenging material for students!</h3>
              <p>The featured collections take educators right to the topics that students most struggle with.</p>
            </div>
            <div data-delay={0} data-animation="slide" className="af-class-download-slider w-slider" data-autoplay="true" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit={2} data-nav-spacing={3} data-duration={500} data-infinite="true">
              <div className="af-class-download-mask w-slider-mask">
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Structured_Literacy" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-1.jpg" loading="lazy" sizes="(max-width: 479px) 83vw, 305px" srcSet="images/download-image-1-p-500.jpg 500w, images/download-image-1.jpg 607w" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Structured Literacy</p>
                  </div>
                </div>
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Fractions" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-2.jpg" loading="lazy" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Fractions</p>
                  </div>
                </div>
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Handwriting" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-3.jpg" loading="lazy" sizes="(max-width: 479px) 83vw, 305px" srcSet="images/download-image-3-p-500.jpg 500w, images/download-image-3.jpg 607w" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Handwriting</p>
                  </div>
                </div>
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Graphic_Organizers" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-4.jpg" loading="lazy" sizes="(max-width: 479px) 83vw, 305px" srcSet="images/download-image-4-p-500.jpg 500w, images/download-image-4.jpg 607w" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Graphic Organizers</p>
                  </div>
                </div>
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Parent_Engagement_-_Math" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-5.jpg" loading="lazy" sizes="(max-width: 479px) 83vw, 305px" srcSet="images/download-image-5-p-500.jpg 500w, images/download-image-5.jpg 607w" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Parent Engagement - Math</p>
                  </div>
                </div>
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Geometry" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-6.jpg" loading="lazy" sizes="(max-width: 479px) 83vw, 305px" srcSet="images/download-image-6-p-500.jpg 500w, images/download-image-6.jpg 607w" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Geometry</p>
                  </div>
                </div>
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Informational_Text" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-7.jpg" loading="lazy" sizes="(max-width: 479px) 83vw, 305px" srcSet="images/download-image-7-p-500.jpg 500w, images/download-image-7.jpg 607w" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Informational Text</p>
                  </div>
                </div>
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Data_Literacy" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-8.jpg" loading="lazy" sizes="(max-width: 479px) 83vw, 305px" srcSet="images/download-image-8-p-500.jpg 500w, images/download-image-8.jpg 607w" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Data Literacy</p>
                  </div>
                </div>
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Number_Sense" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-9.jpg" loading="lazy" sizes="(max-width: 479px) 83vw, 305px" srcSet="images/download-image-9-p-500.jpg 500w, images/download-image-9.jpg 607w" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Number Sense</p>
                  </div>
                </div>
                <div className="af-class-download-slide w-slide">
                  <div className="af-class-download-card">
                    <a href="https://foliok12.com/collection/Rounding_Numbers" target="_blank" className="af-class-download-image-wrap w-inline-block"><img src="images/download-image-10.jpg" loading="lazy" sizes="(max-width: 479px) 83vw, 305px" srcSet="images/download-image-10-p-500.jpg 500w, images/download-image-10.jpg 607w" alt="Collection Image" className="af-class-download-image" /></a>
                    <p className="af-class-download-card-text">Rounding Numbers</p>
                  </div>
                </div>
              </div>
              <div className="af-class-download-arrow af-class-left w-slider-arrow-left">
                <div className="w-icon-slider-left" />
              </div>
              <div className="af-class-download-arrow w-slider-arrow-right">
                <div className="w-icon-slider-right" />
              </div>
              <div className="af-class-download-slide-nav w-slider-nav w-round" />
            </div>
          </div>
        </div>
      </section>
      <section className="af-class-category-section">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div>
            <h2 className="af-class-section-title">Categories</h2>
          </div>
          <div data-w-id="dd3e1c32-06a9-25cc-3265-63c8d0543f23" className="af-class-category-card-wrap">
            <div data-w-id="1b29f58f-e6e0-f941-a35d-ccf3fa79dde1" className="af-class-category-card-area">
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon"><img src="images/category-icon-1.svg" loading="lazy" alt="scale icon" /></div>
                <p className="af-class-download-card-text">Math</p>
              </div>
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon af-class-blue"><img src="images/category-icon-2.svg" loading="lazy" alt="book icon" /></div>
                <p className="af-class-download-card-text">English Language Arts</p>
              </div>
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon af-class-yellow"><img src="images/category-icon-3.svg" loading="lazy" alt="user icon" /></div>
                <p className="af-class-download-card-text">Parent Engagement</p>
              </div>
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon af-class-violet"><img src="images/category-icon-4.svg" loading="lazy" alt="box icon" /></div>
                <p className="af-class-download-card-text">Graphic Organizers</p>
              </div>
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon af-class-green"><img src="images/category-icon-5.svg" loading="lazy" alt="atom icon" /></div>
                <p className="af-class-download-card-text">General Science</p>
              </div>
            </div>
            <div className="af-class-category-card-area af-class-hide">
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon"><img src="images/category-icon-1.svg" loading="lazy" alt="scale icon" /></div>
                <p className="af-class-download-card-text">Math</p>
              </div>
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon af-class-blue"><img src="images/category-icon-2.svg" loading="lazy" alt="book icon" /></div>
                <p className="af-class-download-card-text">English Language Arts</p>
              </div>
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon af-class-yellow"><img src="images/category-icon-3.svg" loading="lazy" alt="user icon" /></div>
                <p className="af-class-download-card-text">Parent Engagement</p>
              </div>
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon af-class-violet"><img src="images/category-icon-4.svg" loading="lazy" alt="box icon" /></div>
                <p className="af-class-download-card-text">Graphic Organizers</p>
              </div>
              <div className="af-class-category-card">
                <div className="af-class-category-card-icon af-class-green"><img src="images/category-icon-5.svg" loading="lazy" alt="atom icon" /></div>
                <p className="af-class-download-card-text">General Science</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="af-class-pricing-section">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div className="af-class-align-center">
            <h2 className="af-class-section-title">Flexible subscription plans</h2>
          </div>
          <div className="af-class-pricing-area">
            <div className="af-class-pricing-card">
              <div className="af-class-pricing-icon-wrap"><img src="images/pricing-icon-1.svg" loading="lazy" alt="user icon" className="af-class-pricing-icon" /></div>
              <h3 className="af-class-pricing-title">Individual</h3>
              <h3 className="af-class-pricing-price">$5/mo</h3>
              <p>Included Services:</p>
              <div className="af-class-pricing-list-area">
                <div className="af-class-pricing-list-item">
                  <div className="af-class-pricing-list-icon"><img src="images/pricing-tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                  <div>Unlimited access to all resources</div>
                </div>
                <div className="af-class-pricing-list-item">
                  <div className="af-class-pricing-list-icon"><img src="images/pricing-tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                  <div>Printable materials for every subject</div>
                </div>
                <div className="af-class-pricing-list-item">
                  <div className="af-class-pricing-list-icon"><img src="images/pricing-tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                  <div>Regular updates with new content</div>
                </div>
              </div>
              <div className="af-class-pricing-button-wrap">
                <a href="https://app.foliok12.com/create-account" target="_blank" className="af-class-primary-button w-inline-block">
                  <div className="af-class-blue-button-wrap">
                    <div>Subscribe now</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="af-class-pricing-card af-class-premium">
              <div className="af-class-pricing-icon-wrap"><img src="images/pricing-icon-2.svg" loading="lazy" alt="house icon" className="af-class-pricing-icon" /></div>
              <h3 className="af-class-pricing-title af-class-white">Whole School Access</h3>
              <h3 className="af-class-pricing-price af-class-white">$490/yr</h3>
              <p className="af-class-pricing-text">Included Services:</p>
              <div className="af-class-pricing-list-area">
                <div className="af-class-pricing-list-item af-class-white">
                  <div className="af-class-pricing-list-icon"><img src="images/white-tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                  <div>Unlimited access for all teachers in the school</div>
                </div>
                <div className="af-class-pricing-list-item af-class-white">
                  <div className="af-class-pricing-list-icon"><img src="images/white-tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                  <div>Individual Logins</div>
                </div>
                <div className="af-class-pricing-list-item af-class-white">
                  <div className="af-class-pricing-list-icon"><img src="images/white-tick-icon.svg" loading="lazy" alt="tick icon" /></div>
                  <div>Access to Teacher Training Webinars</div>
                </div>
              </div>
              <div className="af-class-pricing-button-wrap">
                <a href="https://shop.pssk12.com/products/folio" target="_blank" className="af-class-yellow-button w-button">Get Started</a>
              </div>
            </div>
          </div>
          <div className="af-class-align-center">
            <p>*For district Pricing Options, Contact Us to get a quote.</p>
          </div>
        </div>
      </section>
      <div className="af-class-category-bg-area"><img src="images/center-bg-image.png" loading="lazy" sizes="100vw" srcSet="images/center-bg-image-p-500.png 500w, images/center-bg-image-p-800.png 800w, images/center-bg-image-p-1080.png 1080w, images/center-bg-image.png 1512w" alt className="af-class-category-bg-image" /><img src="images/curve-line-element.png" loading="lazy" sizes="100vw" srcSet="images/curve-line-element-p-500.png 500w, images/curve-line-element-p-800.png 800w, images/curve-line-element.png 1512w" alt="curve line" className="af-class-category-bg-curve-image" /></div>
    </div>
    <section className="af-class-step-section">
      <div className="w-layout-blockcontainer af-class-container w-container">
        <div className="w-layout-grid af-class-step-grid">
          <div id="w-node-_5054154d-1015-6a19-2749-2cae75b63205-baf982fa" className="af-class-step-content-wrap">
            <div>
              <p className="af-class-banner-notice-text">How FolioK12 Works</p>
              <h2 className="af-class-section-title">A Simple <span className="af-class-gradient-text">3-Step</span> Process</h2>
            </div>
            <div className="af-class-step-grid-image-wrap"><img src="images/step-grid-image.png" loading="lazy" width={587} sizes="(max-width: 479px) 92vw, (max-width: 767px) 93vw, 587px" alt="step image" srcSet="images/step-grid-image-p-500.png 500w, images/step-grid-image-p-800.png 800w, images/step-grid-image.png 1174w" />
              <div className="af-class-step-arrow"><img src="images/step-arrow.svg" loading="lazy" alt="arrow" /></div>
            </div>
          </div>
          <div id="w-node-a973ef07-8ffb-9a5c-e353-3418ec642934-baf982fa" className="af-class-step-dropdown-area">
            <div data-hover="false" data-delay={0} className="af-class-step-dropdown w-dropdown">
              <div className="af-class-step-dropdown-top w-dropdown-toggle">
                <div className="af-class-step-dropdown-top-area">
                  <div className="af-class-step-dropdown-icon"><img src="images/step-dropdown-icon-1.svg" loading="lazy" alt="arrow icon" /></div>
                  <div>
                    <div>Step 1</div>
                    <h3 className="af-class-step-dropdown-title">Select Content</h3>
                  </div>
                </div>
                <div className="af-class-step-icon w-icon-dropdown-toggle" />
              </div>
              <nav className="af-class-step-dropdown-bottom w-dropdown-list">
                <div className="af-class-step-dropdown-bottom-wrap">
                  <div className="af-class-step-image-wrap"><img src="images/step-image-1.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 934px) 83vw, 776px" srcSet="images/step-image-1-p-500.jpg 500w, images/step-image-1.jpg 776w" alt="step image" className="af-class-step-image" /></div>
                  <p>Explore FolioK12’s extensive library of educational resources, tailored for teachers and homeschooling parents.</p>
                </div>
              </nav>
            </div>
            <div data-hover="false" data-delay={0} className="af-class-step-dropdown w-dropdown">
              <div className="af-class-step-dropdown-top w-dropdown-toggle">
                <div className="af-class-step-dropdown-top-area">
                  <div className="af-class-step-dropdown-icon"><img src="images/step-dropdown-icon-2.svg" loading="lazy" alt="download icon" /></div>
                  <div>
                    <div>Step 2</div>
                    <h3 className="af-class-step-dropdown-title">Download and Print</h3>
                  </div>
                </div>
                <div className="af-class-step-icon w-icon-dropdown-toggle" />
              </div>
              <nav className="af-class-step-dropdown-bottom w-dropdown-list">
                <div className="af-class-step-dropdown-bottom-wrap">
                  <div className="af-class-step-image-wrap"><img src="images/step-image-2.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 934px) 83vw, 776px" srcSet="images/step-image-2-p-500.jpg 500w, images/step-image-2.jpg 776w" alt="step image" className="af-class-step-image" /></div>
                  <p>Teachers can either print the material or use them on an iPad / SmartBoard.</p>
                </div>
              </nav>
            </div>
            <div data-hover="false" data-delay={0} className="af-class-step-dropdown w-dropdown">
              <div className="af-class-step-dropdown-top w-dropdown-toggle">
                <div className="af-class-step-dropdown-top-area">
                  <div className="af-class-step-dropdown-icon"><img src="images/step-dropdown-icon-3.svg" loading="lazy" alt="tv icon" /></div>
                  <div>
                    <div>Step 3</div>
                    <h3 className="af-class-step-dropdown-title">Share and Engage</h3>
                  </div>
                </div>
                <div className="af-class-step-icon w-icon-dropdown-toggle" />
              </div>
              <nav className="af-class-step-dropdown-bottom w-dropdown-list">
                <div className="af-class-step-dropdown-bottom-wrap">
                  <div className="af-class-step-image-wrap"><img src="images/step-image-3.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 934px) 83vw, 776px" srcSet="images/step-image-3-p-500.jpg 500w, images/step-image-3.jpg 776w" alt="step image" className="af-class-step-image" /></div>
                  <p>Explore FolioK12’s extensive library of educational resources, tailoreIntegrate the resources into your lesson plans to create engaging and effective learning experiences for your students. Save time and enhance your teaching with high-quality, ready-to-use materials.d for teachers and homeschooling parents.</p>
                </div>
              </nav>
            </div>
            <div className="af-class-step-line" />
            <div className="af-class-step-button-wrap">
              <a href="https://app.foliok12.com/create-account" target="_blank" className="af-class-primary-button w-inline-block">
                <div className="af-class-primary-button-wrap">
                  <div>Subscribe now</div>
                </div>
              </a>
              <div className="af-class-step-button-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="af-class-step-bg-image-wrap"><img src="images/step-bg-image.png" loading="lazy" sizes="(max-width: 1190px) 100vw, 1190px" srcSet="images/step-bg-image-p-500.png 500w, images/step-bg-image-p-800.png 800w, images/step-bg-image.png 1190w" alt /></div>
    </section>
    <div className="af-class-optimize-wrap">
      <section className="af-class-optimize-section">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div>
            <div className="w-layout-grid af-class-optimize-grid">
              <div id="w-node-_08dfb7bd-ce6b-ba19-3e0d-29eb178504bf-baf982fa">
                <h2 className="af-class-section-title">Optimize Your Teaching with FolioK12</h2>
              </div>
              <div id="w-node-f9bbf8fe-bc1b-fb39-8a68-2f676788126a-baf982fa" className="af-class-optimize-area">
                <div className="af-class-optimize-card">
                  <h3 className="af-class-optimize-number af-class-violet">70%</h3>
                  <p>time savings on quick lessons preparation</p>
                </div>
                <div className="af-class-optimize-line" />
                <div className="af-class-optimize-card">
                  <h3 className="af-class-optimize-number af-class-yellow">2,500+</h3>
                  <p>schools trust Folio &amp; PSS Poster Maker to produce lesson-supporting resources</p>
                </div>
                <div className="af-class-optimize-line" />
                <div className="af-class-optimize-card">
                  <h3 className="af-class-optimize-number af-class-red">$5/mo</h3>
                  <p>affordable &amp; high-quality resources</p>
                </div>
              </div>
            </div>
            <div className="af-class-optimize-bottom-line" />
          </div>
        </div>
      </section>
      <section className="af-class-testimonial-section">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div className="af-class-align-center">
            <h2 className="af-class-section-title">What do customers say about FolioK12?</h2>
          </div>
          <div data-delay={4000} data-animation="slide" className="af-class-testimonial-slider w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit={0} data-nav-spacing={3} data-duration={500} data-infinite="true">
            <div className="af-class-testimonial-mask w-slider-mask">
              <div className="af-class-testimonial-slide w-slide">
                <div className="af-class-testimonial-card">
                  <div className="af-class-testimonial-quote-icon"><img src="images/quote-icon.svg" loading="lazy" alt="quote icon" /></div>
                  <p className="af-class-testimonial-text">“Making connections with Folio added a spark that was thoroughly enjoyed and beneficial to our school community. I look forward to working with the team at Folio and would recommend them as a valued resource for teachers.”</p>
                  <div className="af-class-testimonial-line" />
                  <div className="af-class-testimonial-details-wrap">
                    <h3 className="af-class-testimonial-name">Mrs. Guadalupe González, Ed.S.</h3>
                    <p className="af-class-testimonial-designation">Assistant Principal, Josefa Ortiz de Domínguez</p>
                  </div>
                </div>
              </div>
              <div className="af-class-testimonial-slide w-slide">
                <div className="af-class-testimonial-card">
                  <div className="af-class-testimonial-quote-icon"><img src="images/quote-icon.svg" loading="lazy" alt="quote icon" /></div>
                  <p className="af-class-testimonial-text">“We know as educators things have to be eye catching and informative to catch the interest of our students - that's society today, the infographic is king.”</p>
                  <div className="af-class-testimonial-line" />
                  <div className="af-class-testimonial-details-wrap">
                    <h3 className="af-class-testimonial-name">Robin C Williams, MLIS, NBCT</h3>
                    <p className="af-class-testimonial-designation">Teacher Librarian, &nbsp;East Mecklenburg High School</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-testimonial-arrow af-class-left w-slider-arrow-left">
              <div className="w-icon-slider-left" />
            </div>
            <div className="af-class-testimonial-arrow af-class-right w-slider-arrow-right">
              <div className="w-icon-slider-right" />
            </div>
            <div className="af-class-testimonial-slide-nav w-slider-nav w-round w-num" />
          </div>
        </div>
      </section>
      <section className="af-class-cta-section">
        <div className="w-layout-blockcontainer af-class-container w-container">
          <div className="af-class-cta-wrap">
            <h2 className="af-class-section-title">Ready to Transform Your Teaching?</h2>
            <div className="af-class-cta-button-wrap">
              <a href="https://app.foliok12.com/create-account" target="_blank" className="af-class-primary-button w-inline-block">
                <div className="af-class-blue-button-wrap">
                  <div>Subscribe now</div>
                </div>
              </a>
            </div>
            <p className="af-class-banner-notice-text">$5 per month. Cancel Anytime.</p>
          </div>
        </div>
        <div className="af-class-cta-blue-vector"><img src="images/cta-vector-blue.svg" loading="lazy" alt /></div>
        <div className="af-class-cta-violet-vector"><img src="images/cta-vector-violet.svg" loading="lazy" alt /></div>
        <div className="af-class-cta-green-vector"><img src="images/cta-vector-green.svg" loading="lazy" alt /></div>
        <div className="af-class-cta-red-vector"><img src="images/cta-vector-red.svg" loading="lazy" alt /></div>
        <div className="af-class-cta-yellow-vector"><img src="images/cta-vector-yellow.svg" loading="lazy" alt /></div>
      </section>
      <div className="af-class-optimize-bg-wrap"><img src="images/optimize-bg.png" loading="lazy" sizes="100vw" srcSet="images/optimize-bg-p-500.png 500w, images/optimize-bg-p-800.png 800w, images/optimize-bg-p-1080.png 1080w, images/optimize-bg.png 1512w" alt className="af-class-optimize-bg-image" /></div>
    </div>
    <section className="af-class-folio-section">
      <div className="w-layout-blockcontainer af-class-container w-container">
        <div className="af-class-align-center">
          <div className="af-class-folio-section-title-wrap">
            <h2 className="af-class-section-title">Interested in Folio for your School?</h2>
            <p>Folio is the perfect companion for the PSS Poster Maker, making poster creation easy and effortless for K-12 educators. Enhance your school's educational resources with our comprehensive library.</p>
          </div>
        </div>
        <div className="af-class-folio-wrap">
          <div className="af-class-folio-bg-wrap" />
          <div className="af-class-folio-card">
            <h3 className="af-class-folio-card-text af-class-white">Folio</h3>
            <div className="af-class-folio-card-text-wrap">
              <p className="af-class-text-white">Access to hundreds of templates, posters, and engaging resources!</p>
            </div>
            <div className="af-class-folio-image-wrap"><img src="images/folio-image-1.png" loading="lazy" width="406.5" sizes="(max-width: 479px) 100vw, (max-width: 767px) 70vw, (max-width: 991px) 34vw, (max-width: 1279px) 36vw, 405px" alt="folio image" srcSet="images/folio-image-1-p-500.png 500w, images/folio-image-1-p-800.png 800w, images/folio-image-1.png 813w" /></div>
          </div>
          <div className="af-class-folio-plus-icon"><img src="images/folio-plus-icon.svg" loading="lazy" alt="plus icon" /></div>
          <div className="af-class-folio-card">
            <div className="af-class-folio-image-wrap"><img src="images/folio-image-2.png" loading="lazy" width={291} sizes="(max-width: 479px) 100vw, (max-width: 767px) 291px, (max-width: 991px) 32vw, 291px" alt="folio image" srcSet="images/folio-image-2-p-500.png 500w, images/folio-image-2.png 582w" /></div>
            <h3 className="af-class-folio-card-text">PSS Poster Maker System</h3>
            <div className="af-class-folio-card-text-wrap">
              <p>Access to hundreds of templates, posters, and engaging resources!</p>
            </div>
            <a href="https://quotes.pssk12.com/" target="_blank" className="af-class-folio-link-wrap w-inline-block">
              <div>Learn more</div>
              <div><img src="images/gradient-arrow.svg" loading="lazy" alt="arrow" /></div>
            </a>
          </div>
          <div className="af-class-folio-wrap-vector"><img src="images/triangle-element.svg" loading="lazy" alt /></div>
        </div>
      </div>
      <div className="af-class-folio-bg-shade" />
    </section>
    <section className="af-class-footer-section">
      <div className="w-layout-blockcontainer af-class-container w-container">
        <div className="af-class-inner-container-950">
          <div className="af-class-footer-area">
            <p className="af-class-copyright-text">© 2024 FolioK12</p>
            <div className="af-class-footer-social-area">
              <a href="https://www.facebook.com/ThePSSBulletin" target="_blank" className="af-class-footer-social-link w-inline-block"><img src="images/fb-icon.svg" loading="lazy" alt="fb icon" /></a>
              <a href="https://www.linkedin.com/company/pssbulletin/" target="_blank" className="af-class-footer-social-link w-inline-block"><img src="images/linkedin-icon.svg" loading="lazy" alt="linkedin icon" /></a>
              <a href="https://www.instagram.com/pssbulletin/" target="_blank" className="af-class-footer-social-link w-inline-block"><img src="images/x-icon.svg" loading="lazy" alt="x icon" /></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</span>
            </span>
          )
        }
      }

      export default IndexView
/* eslint-enable */